import React from "react"
import Layout from "../components/layout"
import styled from 'styled-components'


// import { RichText } from 'prismic-reactjs'
// export const query = graphql`
// query ArticleQuery {  
//   allPrismicArticle {
//     edges {
//       node {
//         id
//         data {
//           title
//         }
//       }
//     }
//   }
// }
// `
 
// {
//   allPrismicArticle {
//     edges {
//       node {
//         id
//         data {
//           body {
//             __typename
//             ... on PrismicArticleBodyRichText {
//               primary {
//                 text_area {
//                   html
//                   text
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }






// worksish
export const query = graphql`
query ArticleQuery($uid: String!) {
  prismicArticle(uid: {eq: $uid}) {
    id
    data {
      body {
        __typename
        ... on PrismicArticleBodyRichText {
          primary {
            text_area {
              html
              text
            }
          }
        }
      }
    }
  }
}
`
const Article = props => {
  // debugger
  // console.log(props.data)
  // const doc = props.data.allArticles.edges.slice(0,1).pop();
  // if(!doc) return null;
  const textAreas = props.data.prismicArticle.data.body.map((item, key) => {
    const text = item.primary.text_area.html;
    // debugger
    return (
      <div dangerouslySetInnerHTML={{ __html: text }}/>
    )
  })


  const Title = styled.h1`
    color: ${props => props.theme.colors.darkBlue};
    font-family: ${props => props.theme.fonts.main};
    padding: 4rem 0 1rem;
    margin: 0;
    font-weight: 600;
  `

  const RichText = styled.div`
    font-family: ${props => props.theme.fonts.main};
    color: ${props => props.theme.colors.black};

    h2 {
      font-size: 1.25rem;
      color: #404452;
    }
  `

  // debugger
  return (
    <div>
      <Layout>
        <div className="container">
            <div className="row ">
                <div className="col-sm-12 col-md-8 offset-sm-0 offset-md-2" >
                  <Title>{props.data.prismicArticle.data.title}</Title>
                  <RichText>
                    {textAreas}
                  </RichText>
                </div>
            </div>
        </div>
        
      </Layout>

      
    </div>
  );
}
 
export default Article;







//   
// export const query = graphql`
// query PageQuery($uid: String) {
//   prismicArticle(uid: $uid) {
//     edges {
//       node {
//         title      
//       }
//     }
//   }
// }
// `
//  
// const Page = props => {
//   const doc = props.data.prismic.allPages.edges.slice(0,1).pop();
//   if(!doc) return null;
//   
//   return (
//     <div>
//       <h1>{RichText.render(doc.node.title)}</h1>
//       {/* <h3>{RichText.render(doc.node.description)}</h3> */}
//     </div>
//   );
// }
//  
// export default Page;





// import React from "react"
// import { graphql } from "gatsby"
// const Article = ({ data: { prismicArticle } }) => {
//   const { data } = prismicArticle
//   return (
//     <React.Fragment>
//       <h1>{data.title.text}</h1>
//       {/* <div dangerouslySetInnerHTML={{ __html: data.content.html }} /> */}
//     </React.Fragment>
//   )
// }
// export default Article
// export const pageQuery = graphql`
//   query ArticleBySlug($uid: String!) {
//     prismicArticle(uid: { eq: $uid }) {
//       uid
//       
//     }
//   }
// `
// 







// import React from "react";
// import { RichText } from 'prismic-reactjs'
//  
// // export const query = graphql`
// //   article(uid:"powered-by-prismic", lang:"en-us"){
// //     title
// //     subtitle
// //   }
// // `
//  
// export default ({ data }) => {
//   // Required check for no data being returned
//   const doc = data.prismic.allHomepages.edges.slice(0,1).pop();
//   if (!doc) return null;
//   
//   return (
//     <div>
//       hi
//       <h1>{RichText.render(doc.node.title)}</h1>
//       {/* <h3>{RichText.render(doc.node.subtitle)}</h3> */}
//     </div>
//   );
// }